import React from "react";
import { Link } from "gatsby";

const Tile = (props) => {
  return (
    <>
      {!props.hideLink && (
        <Link to={props.link} className="linkOverlay tile">
          <div
            className="tile-inner"
            style={{ backgroundImage: `url(${props.image})` }}
          >
            <div className="hoverCopy">
              <p className="linkTitle">{props.title}</p>
              <p className="linkActionCopy">See more</p>
            </div>
          </div>
        </Link>
      )}
      {props.hideLink && (
        <div className="linkOverlay tile">
          <div
            className="tile-inner"
            style={{ backgroundImage: `url(${props.image})` }}
          >
            <div className="hoverCopy">
              <p className="linkTitle">{props.title}</p>
              <p className="linkActionCopy">See more</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Tile;
