import React from "react";

import { pageLinks } from "../data/pageLinks.js";

import Hero from "../components/v2/Hero.jsx";
import Layout from "../components/v2/Layout.jsx";
import PageSection from "../components/v2/PageSection.jsx";
import ContentBlock from "../components/v2/ContentBlock";
import TileGrid from "../components/v2/TileGrid.jsx";
import ImageBlock from "../components/v2/ImageBlock";
import Image from "../components/v2/Image";
import hero_img from "../../src/images/introduction/image-2021-12-21-10-15-21-540.png";
import hilux2 from "../../src/images/introduction/image-2021-12-22-10-02-22-590.png";
import man_through_window from "../../src/images/introduction/image-2021-12-21-10-15-17-811.png";
import car from "../../src/images/introduction/image-2021-12-21-10-15-21-920.png";
import forest from "../../src/images/introduction/image-2021-12-21-10-15-21-631.png";
import beach from "../../src/images/introduction/image-2021-12-21-10-15-21-459.png";
import sun_roof from "../../src/images/introduction/image-2021-12-21-10-15-21-317.png";
import line_up from "../../src/images/introduction/image-2021-12-21-10-15-22-175.png";

import PrevNextNavFooter from "../components/v2/ui-blocks/PrevNextNavFooter/PrevNextNavFooter";
import ImageScroller from "../components/v2/ui-blocks/ImageScroller";

import ToneOfVoiceSeven from "../images/Tone_of_Voice_7.svg";

const introText =
  "The Toyota Visual Identity System is a comprehensive guide designed to ensure all Toyota brand and product communications in New Zealand are unified, consistent and distinctly Toyota. Here, you’ll find everything you need to create your own Toyota marketing materials.";

const Introduction = ({ location }) => {
  return (
    <Layout appLocation={location}>
      <Hero
        image={hero_img}
        text={introText}
        navindex="0"
        textPos={"flex-start"}
        marginTop={"10%"}
      />
      <PageSection sectionId="tone-of-voice">
        <ContentBlock>
          <h2>Tone of voice</h2>
          <div className="text">
            <p>
              We're moving forward to create a greater New Zealand for everyone.
            </p>
            <p>
              We're doing this as an organisation through our three main brand
              journeys: Mobility, Sustainability and Community.
            </p>
          </div>
        </ContentBlock>
        <ImageBlock>
          <Image width="100%" src={hilux2} overlayStyle="right-align">
            <div className="display-title" style={{ color: "white" }}>
              Here’s what makes us Toyota New Zealand
            </div>
          </Image>
        </ImageBlock>
        <ContentBlock>
          <h3>A guide to storytelling and story doing.</h3>
          <div className="text">
            <p>
              Toyota New Zealand is a well-loved brand with a well-established
              brand personality.
            </p>
            <p> We have always been of New Zealand, and for New Zealand. </p>
            <p>
              But as the face of New Zealand changes, with different attitudes,
              concerns and desires we must change and adapt too.
            </p>
            <p>
              We need to use the right tone, in how we talk and how we portray
              ourselves to remain contemporary and relevant to the New Zealand
              of today, and tomorrow.
            </p>
            <p>We want to not only be loved, but also desired.</p>
            <p>We need to remain true to who we are, our values and purpose.</p>
            <p> All the while as we move on with New Zealand. </p>
            <p> Here are some thoughts on how we do that.</p>
          </div>
        </ContentBlock>
        <ImageScroller
          style={{ backgroundColor: "white" }}
          images={[
            {
              image: {
                svg: man_through_window,
                style: { height: "500px", width: "800px" }
              },
              header: {
                copy: "<h3 style='padding-bottom: 18px'>1. To start</h3>We put people first. We look to the needs, concerns and desires of New Zealanders and then align our brand and product stories with them to be relevant, current and meaningful.",
                style: { marginLeft: 0, marginRight: 0 }
              }
            },
            {
              image: {
                svg: car,
                style: { height: "500px", width: "800px" }
              },
              header: {
                copy: "<h3 style='padding-bottom: 18px'>2. To impress</h3>We find the innovation, design, technology, experience or solution in everything we offer, to reinforce our credentials as a forward-looking, mobility company and so continually demonstrate our relevance to today’s New Zealanders.",
                style: { marginLeft: 0, marginRight: 0 }
              }
            },
            {
              image: {
                svg: forest,
                style: { height: "500px", width: "800px" }
              },
              header: {
                copy: "<h3 style='padding-bottom: 18px'>3. To connect</h3>We always look for the human connection. We are not progressive for progress sake. We are not a technology company. We are here to serve people by making mobility accessible to all on their personal journeys, both physical and emotional.",
                style: { marginLeft: 0, marginRight: 0 }
              }
            },
            {
              image: {
                svg: beach,
                style: { height: "500px", width: "800px" }
              },
              header: {
                copy: "<h3 style='padding-bottom: 18px'>4. To engage</h3>We aim for hearts and minds. We use storytelling, humour, conversation, observations, characters with human and cultural truths to engage people. We tell stories that are contemporary and inclusive. We show mobility solutions and vehicles in the ‘real world’. They are not put up on a pedestal, but rather woven in as desirable features of New Zealand life.",
                style: { marginLeft: 0, marginRight: 0 }
              }
              // caption: {
              //     copy: "When the logo can't sit anchored to the base of the artwork use the 1X for the margin widths as shown. ",
              //     style: {marginTop: '-100px'}
              // }
            },
            {
              image: {
                svg: sun_roof,
                style: { height: "500px", width: "800px" }
              },
              header: {
                copy: "<h3 style='padding-bottom: 18px; margin-bottom: 0'>5. To relate</h3>We talk to product features in a way that is relevant to customers. We don’t use jargon and overly technical language. We use communications to make the features of our products understandable, relatable and desirable to all New Zealanders. Our customers are our advocates. We respect them. We don’t talk down to them. We are honest and fair. Customers will more likely show loyalty to us if we show it to them.",
                style: { width: "800", marginLeft: 0, marginRight: 0 }
              }
              // caption: {
              //     copy: "When the logo can't sit anchored to the top of the artwork use the 1X for the margin widths as shown.",
              //     style: {marginTop: '-100px'}
              // }
            },
            {
              image: {
                svg: line_up,
                style: { height: "500px", width: "800px" }
              },
              header: {
                copy: "<h3 style='padding-bottom: 18px'>6. To include</h3>We are inclusive and respectful of all points of views and celebrate the diversity and uniqueness of all New Zealanders. We speak from a diverse New Zealand perspective with a New Zealand voice. This can include the use of te reo Māori words or phrases when considered appropriate. This shouldn’t be forced or tokenism. But rather a reflection of the way te reo is more and more frequently being used as a natural part of our common daily New Zealand vernacular.",
                style: { width: "800", marginLeft: 0, marginRight: 0 }
              }
              // caption: {
              //     copy: "<b>Bottom centered</b><br/>When placing the logo in the centre of the artwork, make sure it sits no closer to the base of the artwork than the 1X as shown.",
              //     style: {marginTop: '-100px'}
              // }
            },
            {
              image: {
                svg: ToneOfVoiceSeven,
                style: { height: "500px", width: "800px" },
                imageSize: "contain"
              },
              header: {
                copy: "<h3 style='padding-bottom: 18px'>7. To sum up</h3>",
                style: { marginLeft: 0, marginRight: 0 }
              }
            }
          ]}></ImageScroller>
      </PageSection>
      <PageSection sectionId="brand-elements">
        <ContentBlock>
          <h2>Brand elements</h2>
          <h3>Brand visual tool kit</h3>
          <div className="text">
            <p>
              The Toyota Visual Identity System currently comprises of eight
              core elements that help unify and communicate our brand with
              consistency and clarity.
            </p>
            <p>
              They include photography, logos, typography, colour, the{" "}
              <em>Let’s Go Places</em> tagline, Messaging Platform and Product
              Campaigns.{" "}
            </p>
          </div>
        </ContentBlock>
      </PageSection>
      <PageSection sectionId="tile-grid">
        <TileGrid appLocation={location} />
      </PageSection>
      <PrevNextNavFooter
        nextText="Next"
        nextHeader="Logo"
        nextUrl={pageLinks.logo}
      />
    </Layout>
  );
};
export default Introduction;