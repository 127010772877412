import React from "react";

import Tile from "./Tile";

import intro_elements_A1_desktop_jpg from "../../images/tiles/intro_elements_A1_desktop.jpg";
import intro_tile_2_tagline from "../../images/tiles/intro-tile-2-tagline.jpg";
import Intro_Typography_svg from "../../images/tiles/Intro_Typography.svg";
import intro_elements_A2_desktop_jpg from "../../images/tiles/intro-tile-4-photography.jpg";
import intro_elements_A3_svg from "../../images/tiles/intro_elements_A3.svg";
import intro_element_messaging_platform from "../../images/tiles/intro-tile-7-messaging-platform.jpg";
import intro_element_product_campaign from "../../images/tiles/intro-tile-8-product-campaigns.jpg";
import intro_tile_6_partnerships from '../../images/tiles/intro-tile-6-partnerships.jpg';

import { pageLinks } from "../../data/pageLinks";

const TileGrid = () => {

  return (
    <div className="tile-grid">
      {/* here need to have a block of 4 images in a row, two rows */}
      <div style={{ display: "flex" }}>
        <div className="row">
          <Tile
            link={pageLinks.logo}
            title="Logo"
            image={intro_elements_A1_desktop_jpg}
          />
          <Tile
            link={pageLinks.tagline}
            title="Tagline"
            image={intro_tile_2_tagline}
          />
          <Tile
            link={pageLinks.typography}
            title="Typography"
            image={Intro_Typography_svg}
          />
          <Tile
            link={pageLinks.photography}
            title="Photography"
            image={intro_elements_A2_desktop_jpg}
          />
          <Tile
            link={pageLinks.colours}
            title="Colours"
            image={intro_elements_A3_svg}
          />
          <Tile
            link={pageLinks.partnerships}
            title="Partnerships"
            image={intro_tile_6_partnerships}
          />
          <Tile
            link={pageLinks["hey-toyota"]}
            title="Hey Toyota"
            image={intro_element_messaging_platform}
          />
          <Tile
            link={pageLinks.campaigns}
            title="Product Campaigns"
            image={intro_element_product_campaign}
          />
        </div>
      </div>
    </div>
  );
};

export default TileGrid;